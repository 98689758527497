import {
  IS_TESTAPI_SERVICE_URL_Enabled,
  Layout_Key,
  Remove_Widget,
  SaveWidget,
  UpdateWidget,
} from "../Constants";

const EmptySaveWidgetSettings = (widgetId, widgetType, settings) => {
  let url = `${SaveWidget}?widgetId=${widgetId}&widgetType=${widgetType}`;
  return fetch(url, {
    method: "PUT",
    body: JSON.stringify(settings),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  }).then((response) => response);
};

const UpdateWidgetSetting = (widgetId, widgetType, settings) => {
  //let url = `${UpdateWidget}?widgetId=${widgetId}&widgetType=${widgetType}`;
  let url = `${UpdateWidget}?widgetId=${widgetId}`;
  return fetch(url, {
    method: "PUT",
    body: JSON.stringify(settings),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  }).then((response) => response);
};

const RemoveWidgetSettings = (widgetId, sessionId) => {
  let url = `${Remove_Widget}?widgetId=${widgetId}&sessionId=${sessionId}&isTestApi=${IS_TESTAPI_SERVICE_URL_Enabled}`;
  return fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  }).then((response) => response);
};

const getWidgetDataById = (widgetId) => {
  let data = getLocalStorageLayout().Widgets;
  for (var i = 0; i < data.length; i++) {
    if (data[i].Id === widgetId) {
      return data[i];
    }
  }
  return null;
};

const getLocalStorageLayout = () => {
  return JSON.parse(localStorage.getItem(Layout_Key));
};

const setLocalStorageLayout = (layout) => {
  localStorage.setItem(Layout_Key, JSON.stringify(layout));
  return layout;
};

const setWidgetDataById = (widgetId, data, obj) => {
  for (var i = 0; i < data.Widgets.length; i++) {
    if (data.Widgets[i].Id === widgetId) {
      data.Widgets[i].WidgetType = obj.WidgetType;
      data.Widgets[i].Type = parseInt(obj.Type);
      data.Widgets[i].Twitter = obj.Twitter;
      data.Widgets[i].Title = obj.Title;
      data.Widgets[i].ShortTitle = obj.ShortTitle;
    }
  }

  return data;
};

const swapCookieLayout = (widget_1, widget_2) => {
  let layout = getLocalStorageLayout();
  if (layout && layout.Widgets) {
    const getIndexofWidget1 = layout.Widgets.findIndex(
      (x) => x.SortOrder == widget_1.SortOrder
    );
    const getIndexofWidget2 = layout.Widgets.findIndex(
      (x) => x.SortOrder == widget_2.SortOrder
    );

    const widgetdt1 = { ...layout.Widgets[getIndexofWidget1] };
    const widgetdt2 = { ...layout.Widgets[getIndexofWidget2] };

    let widget1 = { ...layout.Widgets[getIndexofWidget1] };
    let widget2 = { ...layout.Widgets[getIndexofWidget2] };

    widget1.Title = widgetdt2.Title;
    widget1.Id = widgetdt2.Id;
    widget1.Type = widgetdt2.Type;
    widget1.ShortTitle = widgetdt2.ShortTitle;
    widget1.Configuration = widgetdt2.Configuration;
    widget1.Twitter = widgetdt2.Twitter;

    widget2.Title = widgetdt1.Title;
    widget2.Id = widgetdt1.Id;
    widget2.Type = widgetdt1.Type;
    widget2.ShortTitle = widgetdt1.ShortTitle;
    widget2.Configuration = widgetdt1.Configuration;
    widget2.Twitter = widgetdt1.Twitter;

    layout.Widgets[getIndexofWidget1] = widget1;
    layout.Widgets[getIndexofWidget2] = widget2;
  }
  setLocalStorageLayout(layout);
};

export const AddNewWidgetSettings = EmptySaveWidgetSettings;
export const UpdateWidgetSettings = UpdateWidgetSetting;
export const RemoveWidget = RemoveWidgetSettings;
export const GetWidgetDataById = getWidgetDataById;
export const SetLocalStorageLayout = setLocalStorageLayout;
export const SetWidgetDataById = setWidgetDataById;
export const GetLocalStorageLayout = getLocalStorageLayout;
export const SwapLayoutInLocalStorage = swapCookieLayout;
