import { Fragment, React, useRef, useState } from "react";
import { useEffect } from "react";
import * as d3 from "d3";
import _ from "lodash";
// import { OOSDt } from "./OOSData";
import "./OOS.css";
import { useSelector } from "react-redux";
import { sampleDataWithSingleBubble } from "./SampleData/OOSDataSingle";

const Margin = { LEFT: 30, RIGHT: 0, TOP: 12, BOTTOM: 18 };
const fontSize = "16px";

const OOSWidget = (
  svgElement,
  widgetId,
  _Height,
  Width,
  slots,
  xAxisMax,
  wonDollarValue,
  salesPersons,
  collorPallet
) => {
  let Height = _Height;
  let colors = [
    "#5f8b95",
    "#ba4d51",
    "#af8a53",
    "#955f71",
    "#859666",
    "#7e688c",
    "#91bdc7",
    "#ec7f83",
    "#e1bc85",
    "#c791a3",
    "#b7c898",
    "#B09ABE",
    "#2D5963",
    "#881B1F",
    "#7D5821",
    "#632D3F",
    "#536434",
    "#4C365A",
  ];

  colors.forEach(function (d) {
    colors.push(d);
  });

  if (collorPallet.length > 0) {
    colors = collorPallet;
  }

  let legends = {
    text: wonDollarValue,
    salesPerson: [...salesPersons],
  };

  //generate legend
  const getLegend = legendGenerator(colors, legends);
  document.getElementById(`${widgetId}-dom-legend`).innerHTML = `${getLegend}`;
  const tooltip = d3.select("#tooltip-oos");

  const legend = document.getElementById(`${widgetId}-dom-legend`);

  Height = _Height - legend.clientHeight - 1;

  const svg = svgElement;
  svg.attr("height", Height + Margin.BOTTOM + Margin.TOP + Margin.TOP);
  svg.attr("style", "margin-top: -12px;");
  const x1Scale = d3.scaleLinear().domain([0, xAxisMax]).range([0, Width]);
  const yScale = d3.scaleBand(); //.paddingInner(0.1); //.paddingOuter(0.1);
  const xScale = d3.scaleBand(); //.paddingInner(0.1).paddingOuter(0.1);
  const y1Scale = d3.scaleLinear().domain([0, Height]).range([Height, 0]);
  let maxDollarValue_op = 0;

  // Remove Old Graph
  svg.selectAll("g").remove();
  svg.selectAll("text").remove();

  const dollarValueScale = d3
    .scaleLinear()
    .domain([4500, 13000])
    .range([37, 55]);

  const radiusScale = d3.scaleSqrt().domain([0, 13000]).range([37, 55]);
  const barLineScale = d3.scaleLinear().domain([10, 100]).range([1, 10]);

  const barGroup = svg
    .append("g")
    .attr("class", "bar")
    .attr("transform", `translate(${Margin.LEFT},${Margin.TOP})`);

  //draw x-axis back ground color
  const xAxisLowerPart = svg
    .append("g")
    .attr("class", "x-axis-lower")
    .attr("transform", `translate(${Margin.LEFT},${Margin.TOP})`);

  const yAxisGroup = svg
    .append("g")
    .attr("class", "y-axis-oos")
    .attr("transform", `translate(${Margin.LEFT},${Margin.TOP})`);

  const xAxisGroup = svg
    .append("g")
    .attr("class", "x-axis-oos")
    .attr("transform", `translate(${Margin.LEFT},${Height + Margin.TOP})`);

  const x1AxisGroup = svg
    .append("g")
    .attr("class", "x1-axis")
    .attr("transform", `translate(0,${Height})`);

  const y1AxisGroup = svg
    .append("g")
    .attr("class", "y1-axis")
    .attr("transform", `translate(${Margin.LEFT},${Margin.TOP})`);

  const activityBarGroup = svg
    .append("g")
    .attr("class", "activity-bar")
    .attr("transform", `translate(${Margin.LEFT},${Margin.TOP})`);

  const bubbleGroup = svg
    .append("g")
    .attr("class", "bubble-entity")
    .attr("transform", `translate(${Margin.LEFT},${Margin.TOP})`);

  const text = svg
    .append("text")
    .attr("x", 0)
    .attr("y", Height / 2)
    .attr("dy", ".35em");

  function drawChart(data, stages) {
    const minslotValue = d3.min(
      data.map((x) => {
        return d3.min(x.bubbles.map((x) => x.activityCount));
      })
    );

    const maxslotValue = d3.max(
      data.map((x) => {
        return d3.max(x.bubbles.map((x) => x.activityCount));
      })
    );

    const minDollarValue = d3.min(
      data.map((x) => {
        return d3.min(x.bubbles.map((x) => x.dollarValue));
      })
    );

    const maxDollarValue = d3.max(
      data.map((x) => {
        return d3.max(x.bubbles.map((x) => x.dollarValue));
      })
    );

    maxDollarValue_op = maxDollarValue;

    barLineScale.domain([minslotValue, maxslotValue]);

    //yScale.domain(data.map((x) => x.stageName)).range([0, Height]);
    console.log("stages", stages);
    yScale.domain(stages).range([0, Height]);

    dollarValueScale
      .domain([minDollarValue, maxDollarValue])
      .range([yScale.bandwidth() / 2, (yScale.bandwidth() / 2) * 1.5]);

    xScale
      //.domain(["0-30 days", "31-60 days", "61-90 days", ">90 days"])
      .domain(slots)
      .range([0, Width]);

    const yAxisCaller = yAxisGroup.call(
      d3.axisLeft(yScale).tickSizeInner(0).tickSizeOuter(0)
    );
    const xAxisCaller = xAxisGroup.call(
      d3.axisBottom(xScale).tickSizeInner(0).tickSizeOuter(0).tickPadding(10)
    );

    //const x1AxisCaller = x1AxisGroup.call(d3.axisBottom(x1Scale));

    //const y1AxisCaller = y1AxisGroup.call(d3.axisLeft(y1Scale));

    let getMaxTspan = 0;

    yAxisCaller
      .selectAll("text")
      .attr("transform", "rotate(-90)")
      .style("text-anchor", "middle")
      .style("color", "#ffffff")
      .style("font-size", "16px")
      .style("font-weight", "500")
      .call(wrap, yScale.bandwidth() - 10); //minus value donated as padding to text

    yAxisCaller.selectAll(".tick").attr("transform", (d, i, el) => {
      const tspanLength = d3.select(el[i]).selectAll("text tspan")
        ._groups[0].length;

      if (tspanLength > getMaxTspan) {
        getMaxTspan = tspanLength;
      }

      //calculation depend on the font-size of label e.g. 16
      return `translate(${tspanLength > 1 ? -16 * tspanLength : -16},${
        yScale(d) + yScale.bandwidth() / 2 - 4
      })`;
    });

    yAxisCaller.attr(
      "transform",
      `translate(${getMaxTspan * 16 + 18},${Margin.TOP})`
    );

    var element = d3.select(".y-axis-oos").node();
    const yAxisWidth = element.getBoundingClientRect().width + 5;

    //update x-axis
    xScale
      //.domain(["0-30 days", "31-60 days", "61-90 days", ">90 days"])
      .domain(slots)
      .range([0, Width - Math.abs(Margin.LEFT - yAxisWidth)]);

    const xAxisCaller1 = xAxisGroup.call(
      d3.axisBottom(xScale).tickSizeInner(0).tickSizeOuter(0).tickPadding(10)
    );

    xAxisCaller1.attr(
      "transform",
      `translate(${Margin.LEFT + Math.abs(Margin.LEFT - yAxisWidth)},${
        Height + Margin.TOP
      })`
    );

    const boxData1 = [];
    for (let index = 0; index < stages.length; index++) {
      const stage = stages[index];
      const stageIndex = stages.length - 1 - index;
      for (let index = 0; index < slots.length; index++) {
        const slot = slots[index];

        //collect and process data
        // Step 1: Find the matching element with the corresponding stageName
        const matchingElement = data.find((x) => x.stageName === stage);

        // Step 2: If a matching element exists, filter its bubbles
        const data1 = matchingElement
          ? matchingElement.bubbles.filter(
              (d) => d["x-axis"] === slot.toString()
            )
          : []; // Default to an empty array if no match is found
        boxData1.push({
          stageName: stage,
          slot: slot,
          stageIndex: stageIndex,
          bubbles: data1,
          bubblesCount: data1.length,
        });
      }
    }

    const boxData = [...boxData1].sort(function (a, b) {
      return b.bubblesCount - a.bubblesCount;
    });

    console.log("boxData", boxData);

    //horizontal lines
    const _xArea = d3
      .select(".x-axis-oos")
      .append("g")
      .attr("class", "vertical-line-container")
      .selectAll("vertical-line")
      //.data(["0-30 days", "31-60 days", "61-90 days", ">90 days"]);
      .data(slots);
    _xArea.exit().remove();

    _xArea
      .enter()
      .append("line")
      .attr("class", "vertical-line")
      .attr("x1", (d, i) => {
        return xScale(d) + xScale.bandwidth();
      })
      .attr("x2", (d, i) => xScale(d) + xScale.bandwidth())
      .attr("y1", (d) => 0)
      .attr("y2", (d) => -Height)
      .attr("stroke", "#BFBFBF")
      .attr("fill", "#BFBFBF")
      .attr("stroke-width", "3")
      .style("stroke-dasharray", "10,5,1,5")
      .attr("shape-rendering", "crispEdges");

    //update x-axis linear scale
    const range_width = Width + Margin.LEFT;
    x1Scale.range([
      Margin.LEFT + Math.abs(Margin.LEFT - yAxisWidth),
      range_width,
    ]);

    //const x1AxisCaller1 = x1AxisGroup.call(d3.axisBottom(x1Scale));

    //console.log("x1Scale(10)", x1Scale(10) - Margin.LEFT);

    //xAxisLowerPart
    const lowerRect = xAxisLowerPart;

    //remove
    lowerRect.exit().remove();

    //update
    lowerRect
      .attr("y", Height)
      .attr("x", (d) => {
        return -yAxisWidth;
      })
      .attr("height", (d) => {
        return "30";
      })
      .attr("width", Width + yAxisWidth)
      .attr("fill", "#BFBFBF")
      .attr("shape-rendering", "crispEdges");

    //create
    lowerRect
      .append("rect")
      .attr("y", Height)
      .attr("x", (d) => {
        return -Margin.LEFT;
      })
      .attr("height", (d) => {
        return "30";
      })
      .attr("width", Width + Margin.LEFT + 1)
      .attr("fill", "#BFBFBF")
      .attr("shape-rendering", "crispEdges");

    lowerRect
      .append("text")
      .attr("x", -yAxisWidth / 2 + Math.abs(Margin.LEFT - yAxisWidth))
      .attr("y", Height + 20)
      .text("AGE")
      .attr("font-size", "14px")
      .style("font-weight", "bold")
      .attr("text-anchor", "middle")
      .attr("fill", "black");

    console.log("data", data);
    const rect = barGroup.selectAll("rect").data(data);

    //remove elaments
    rect.exit().remove();

    //update elements
    rect
      .attr("y", (d) => {
        return yScale(d["stageName"]);
      })
      .attr("x", (d) => {
        return -yAxisWidth;
      })
      .attr("height", (d) => {
        return yScale.bandwidth();
      })
      .attr("width", Width + yAxisWidth)
      .attr("fill", (d, i) => {
        if (d["stageName"].toLowerCase().indexOf("won") > -1) {
          return "#789440";
        }
        return i % 2 === 0 ? "#3F3F3F" : "#595959";
      })
      .attr("stroke", (d, i) => {
        if (d["stageName"].toLowerCase().indexOf("won") > -1) {
          return "#789440";
        }
        return i % 2 === 0 ? "#3F3F3F" : "#595959";
      });

    //add new elemets
    rect
      .enter()
      .append("rect")
      .attr("y", (d) => {
        console.log("d-rect", d);
        return yScale(d["stageName"]);
      })
      .attr("x", (d) => {
        return -yAxisWidth;
      })
      .attr("height", (d) => {
        return yScale.bandwidth();
      })
      .attr("width", Width + yAxisWidth)
      .attr("fill", (d, i) => {
        if (d["stageName"].toLowerCase().indexOf("won") > -1) {
          return "#789440";
        }
        return i % 2 === 0 ? "#3F3F3F" : "#595959";
      })
      .attr("stroke", (d, i) => {
        if (d["stageName"].toLowerCase().indexOf("won") > -1) {
          return "#789440";
        }
        return i % 2 === 0 ? "#3F3F3F" : "#595959";
      });

    //append box container for display bubble group
    const _box = d3
      .select(".x-axis-oos")
      .append("g")
      .attr("class", "bubbles-container")
      .selectAll("box-container")
      // .data(slots);
      .data(boxData);
    _box.exit().remove();

    _box
      .enter()
      .append("g")
      .attr("class", "box-container")
      .attr("x", function (d) {
        return xScale(d.slot);
      })
      .attr("y", function (d, i) {
        const _stageName = stages[d.stageIndex];
        return -(yScale(_stageName) + yScale.bandwidth());
      })
      .attr("transform", (d) => {
        const _stageName = stages[d.stageIndex];
        //return `translate(${xScale(d.slot) - yScale.bandwidth() * 0.05},${-(
        return `translate(${xScale(d.slot)},${-(
          //(yScale(_stageName) + yScale.bandwidth()) //5% of height
          (yScale(_stageName) + yScale.bandwidth() + yScale.bandwidth() * 0.05) //5% of height
        )})`;
      });

    // Dynamic padding based on bubble count
    let dynamicPadding = 2;
    const getSingleElementMaxVal = boxData
      .filter((x) => x.bubblesCount == 1)
      .map((x) => {
        return Math.max(x.bubbles.map((x) => x.dollarValue));
      });
    const singleBubbleMaxVal = Math.max(...getSingleElementMaxVal);

    //Create the bubbles
    d3.selectAll(".box-container").each(function (dt, i) {
      const index = i;
      var element = d3.select(this).node();
      var getElementData = d3.select(this).data()[0];
      const _width = xScale.bandwidth(); //+ yScale.bandwidth() * 0.1; //element.getBoundingClientRect().width;
      const _height = yScale.bandwidth() + yScale.bandwidth() * 0.05 * 2; //element.getBoundingClientRect().height;

      const bubblesDt = getElementData.bubbles.sort(function (a, b) {
        return a.dollarValue - b.dollarValue;
      });

      const bubbleCount = bubblesDt.length;
      // Dynamic padding based on bubble count
      if (bubbleCount <= 60) {
        dynamicPadding =
          (Math.min(_width, _height) / Math.sqrt(bubbleCount)) * 0.25;
      }

      console.log(
        "dynamicPadding",
        dynamicPadding,
        "bubble count",
        bubbleCount
      );

      //calculate only for large number of bubbles count
      if (index == 0) {
        // Calculate dynamic min and max radius
        const availableArea = _width * _height;
        const averageBubbleArea = availableArea / bubbleCount;

        const calclulateTheAvgOfMin =
          bubbleCount >= 1 && bubbleCount <= 4 ? 0.15 : 0.4; // 30% or 40% of the average

        const dynamicMinRadius =
          Math.sqrt(averageBubbleArea / Math.PI) * calclulateTheAvgOfMin; // 40% of the average
        const dynamicMaxRadius = Math.min(
          Math.sqrt(averageBubbleArea / Math.PI) * 1.2, // 120% of the average
          _height / 4, // Limit based on height
          _width / 8 // Limit based on width
        );

        const getMaxVal = Math.max(...bubblesDt.map((x) => x.dollarValue));
        let updatedMaxRadius = dynamicMaxRadius;

        // Define scales dynamically based on data values
        radiusScale.domain([0, maxDollarValue]).range([0, dynamicMaxRadius]);

        if (
          bubbleCount >= 1 &&
          bubbleCount <= 4 &&
          (singleBubbleMaxVal > getMaxVal || singleBubbleMaxVal === getMaxVal)
        ) {
          console.log("bubbleCount == 1");
          updatedMaxRadius = yScale.bandwidth() / 2 + yScale.bandwidth() * 0.05;
          const getUpdatedMinVal = updatedMaxRadius - dynamicMaxRadius;
          console.log("updatedMaxRadius", updatedMaxRadius, getUpdatedMinVal);
          radiusScale.range([
            0, //dynamicMinRadius + getUpdatedMinVal * 0.8,
            updatedMaxRadius,
          ]);
        }

        //Handle only single bubble
        if (bubbleCount === 1) {
          updatedMaxRadius = yScale.bandwidth() / 2 + yScale.bandwidth() * 0.05;
          const getUpdatedMinVal = updatedMaxRadius - dynamicMaxRadius;
          console.log("updatedMaxRadius", updatedMaxRadius, getUpdatedMinVal);
          radiusScale.domain([0, maxDollarValue]).range([
            0, //dynamicMinRadius + getUpdatedMinVal * 0.8,
            updatedMaxRadius,
          ]);
        }

        //define the min and max size of line bar
        barLineScale.range([dynamicMinRadius / 2, updatedMaxRadius * 1.25]); //min is half of min radius and max is 1.25x of max bubble size
      }

      // Create nodes with radius based on data
      const nodes = bubblesDt.map((d) => ({
        id: d.groupIndex,
        radius: radiusScale(d.dollarValue),
        value: d.dollarValue,
        salesPerson: d.salesPerson,
        text: d.text,
        activityCount: d.activityCount,
        organization: d.organization,
        x: Math.random() * _width,
        y: Math.random() * _height,
      }));

      // Create a simulation
      const simulation = d3
        .forceSimulation(nodes)
        .force("x", d3.forceX(_width / 2).strength(0.1)) // Center bubbles
        .force("y", d3.forceY(_height / 2).strength(0.1)) // Center bubbles
        .force(
          "collision",
          d3.forceCollide((d) => d.radius + dynamicPadding)
        ) // Add padding
        .force("box", boxForce(0, 0, _width, _height))
        .on("tick", ticked);

      // Custom force to keep nodes inside the rectangle
      function boxForce(x0, y0, x1, y1) {
        return () => {
          nodes.forEach((node) => {
            if (node.x - node.radius < x0) node.x = x0 + node.radius;
            if (node.x + node.radius > x1) node.x = x1 - node.radius;
            if (node.y - node.radius < y0) node.y = y0 + node.radius;
            if (node.y + node.radius > y1) node.y = y1 - node.radius;
          });
        };
      }

      //Draw bar
      const barLine = d3
        .select(element)
        .selectAll(".barline")
        .data(nodes)
        .enter()
        .append("line")
        .attr("stroke", "#BFBFBF")
        .attr("stroke-width", "4")
        .on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseout", function () {
          tooltip.style("opacity", 0);
        });

      //Draw barconnetor top
      const barLineTopConnector = d3
        .select(element)
        .selectAll(".line-connector")
        .data(nodes)
        .enter()
        .append("line")
        .attr("stroke", "#BFBFBF")
        .attr("stroke-width", "4")
        .attr("stroke-linecap", "round")
        .on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseout", function () {
          tooltip.style("opacity", 0);
        });

      //Draw barconnetor bottom
      const barLineBottomConnector = d3
        .select(element)
        .selectAll(".line-connector")
        .data(nodes)
        .enter()
        .append("line")
        .attr("stroke", "#BFBFBF")
        .attr("stroke-width", "4")
        .attr("stroke-linecap", "round")
        .on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseout", function () {
          tooltip.style("opacity", 0);
        });

      // Draw circles with dynamic radius and colors
      const circles = d3
        .select(element)
        .selectAll(".bubble")
        .data(nodes)
        .enter()
        .append("circle")
        .attr("class", "bubble")
        .attr("r", (d) => {
          return d.radius;
        })
        .attr("fill", (d, i) => {
          const salesPersonIndex = legends.salesPerson.findIndex(
            (x) => x.toString() === d.salesPerson.toString()
          );
          return colors[salesPersonIndex];
        })
        .attr("stroke", (d, i) => {
          const salesPersonIndex = legends.salesPerson.findIndex(
            (x) => x.toString() === d.salesPerson.toString()
          );
          return colors[salesPersonIndex];
        })
        .attr("fill-opacity", 0.5)
        .attr("stroke-width", "1")
        .on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseout", function () {
          tooltip.style("opacity", 0);
        });

      // Add labels to display value inside the bubbles
      const labels = d3
        .select(element)
        .selectAll(".label")
        .data(nodes)
        .enter()
        .append("text")
        .attr("class", "label")
        .selectAll("tspan")
        .data((d) => {
          const nfObject = new Intl.NumberFormat("en-US");
          const formattedNumber = nfObject.format(d.value);
          const dollarValueText = `$${formattedNumber} (${d.activityCount} slots)`;
          const companyName = d.text;

          return [
            {
              text: companyName,
              isText: true,
              r: d.radius,
              value: d.value,
              salesPerson: d.salesPerson,
              activityCount: d.activityCount,
              organization: d.organization,
            },
            {
              text: dollarValueText,
              activityCount: d.activityCount,
              isText: false,
              r: d.radius,
              value: d.value,
              salesPerson: d.salesPerson,
              organization: d.organization,
            },
          ];
        })
        .enter()
        .append("tspan")
        .attr("fill", "#FFFFFF")
        .style("font-size", function (d) {
          const len = d.text.substring(0, d.r / 3).length;
          let size = d.r / 3;
          size *= 10 / len;
          size += 1;
          const s = d.r / 2 > 16 ? 16 : d.r / 2;
          return s + "px";
        })
        .attr("dy", (d, i) => (i === 0 ? "-0.2em" : "1.1em")) // Offset for multi-line text
        .text((d) => {
          const text = d.text.substring(0, d.r / 3);
          if (d.text.length > text.length) {
            if (d.isText) {
              const acronym = convertTextToAcronym(d.text);
              return acronym;
            } else {
              const dollarValue = Number(d.value);
              // const dollarValueText = `$${d3.format(".3~s")(dollarValue)} (${
              //   d.activityCount
              // } slots)`;
              const dollarValueText = `$${d3.format(".3~s")(dollarValue)} (${
                d.activityCount
              })`;
              return dollarValueText;
            }
          }
          return text;
        })
        .on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseout", function () {
          tooltip.style("opacity", 0);
        });

      // Update positions on each tick
      function ticked() {
        barLine
          .attr("x1", (d) => d.x)
          .attr("x2", (d) => d.x)
          .attr("y1", (d) => d.y - barLineScale(d.activityCount))
          .attr("y2", (d) => d.y + barLineScale(d.activityCount));

        barLineTopConnector
          .attr("x1", (d) => d.x - 3.5)
          .attr("x2", (d) => d.x + 3.5)
          .attr("y1", (d) => d.y - barLineScale(d.activityCount))
          .attr("y2", (d) => d.y - barLineScale(d.activityCount));

        barLineBottomConnector
          .attr("x1", (d) => d.x - 3.5)
          .attr("x2", (d) => d.x + 3.5)
          .attr("y1", (d) => d.y + barLineScale(d.activityCount))
          .attr("y2", (d) => d.y + barLineScale(d.activityCount));

        circles.attr("cx", (d) => d.x).attr("cy", (d) => d.y);

        d3.select(element)
          .selectAll(".label")
          .data(nodes)
          .attr("x", (d) => d.x)
          .attr("y", (d) => d.y)
          .selectAll("tspan")
          .attr("x", function (d, i, nodes) {
            const parentNode = nodes[i].parentNode.__data__;
            return parentNode.x;
          });
      }

      function mousemove(event) {
        const tooltipWidth = tooltip.node().offsetWidth;
        const tooltipHeight = tooltip.node().offsetHeight;

        // Cursor position
        let left = event.pageX + 10;
        let top = event.pageY + 10;

        // Viewport dimensions
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        // Adjust for right edge
        if (left + tooltipWidth > viewportWidth) {
          left = event.pageX - tooltipWidth - 10;
        }

        // Adjust for bottom edge
        if (top + tooltipHeight > viewportHeight) {
          top = event.pageY - tooltipHeight - 10;
        }

        tooltip.style("left", `${left}px`).style("top", `${top}px`);
      }

      function mouseover(event, d) {
        const nfObject = new Intl.NumberFormat("en-US");
        const formattedNumber = nfObject.format(d.value);
        tooltip
          .style("opacity", 1)
          .html(
            `Salesperson: ${d.salesPerson}<br>Opportunity Value: $${formattedNumber}<br>Slots: ${d.activityCount}<br>Organization: ${d.organization}`
          );
      }
    });
  }

  function wrap(text, width) {
    text.each(function () {
      let text = d3.select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        //lineNumber = 0,
        lineHeight = 1.2, // ems
        x = text.attr("x"),
        y = text.attr("y"),
        dy = 0; // parseFloat(text.attr("dy")),
      let tspan = text
        .text(null)
        .append("tspan")
        .style(
          "font-family",
          '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji'
        )
        .attr("x", x)
        .attr("y", y)
        .attr("dy", dy + "em");
      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text
            .append("tspan")
            .style(
              "font-family",
              '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji'
            )
            .attr("x", x)
            .attr("y", y)
            //.attr("dy", ++lineNumber * lineHeight + dy + "em")
            .attr("dy", lineHeight + dy + "em")
            .text(word);
        }
      }
    });
  }

  function convertTextToAcronym(text) {
    const words = text.split(" ");
    let acronyms = "";

    for (const word of words) {
      acronyms += word[0];
    }

    return acronyms.toLocaleUpperCase();
  }

  function legendGenerator(_colors, legends) {
    let colorsObj = {};

    colorsObj = _colors;

    let barLegendDOM = "";
    let lineLegendDOM = "";
    const nfObject = new Intl.NumberFormat("en-US");
    const formattedNumber = nfObject.format(legends.text);

    const labelDOM =
      "<div style=\"display:inline-block;margin-left:4px;color:#FFFFFF;font-family: 'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana;font-weight: 500;font-size: 14px;\">Closed Won Value: ".concat(
        `$${formattedNumber}`,
        "</div>"
      );
    barLegendDOM += "<div class='legendBoxContent'>".concat(labelDOM, "</div>");

    barLegendDOM =
      "<div class='barLegendBox' style=\"flex-basis: 50% !important;justify-content: flex-start;\">".concat(
        barLegendDOM,
        "</div>"
      );

    if (legends.salesPerson.length > 0) {
      for (let j = 0; j < legends.salesPerson.length; j++) {
        let _color = colorsObj[j];

        const _boxDOM =
          '<div style="display:inline-block;width:14px;height:14px;border-radius:6px;background:'.concat(
            _color,
            '"></div>'
          );
        const _labelDOM =
          "<div style=\"display:inline-block;margin-left:4px;color:#FFFFFF;font-family: 'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana;font-weight: 400;font-size: 14px;\">".concat(
            legends.salesPerson[j],
            "</div>"
          );
        lineLegendDOM +=
          '<div style="display:flex;align-items:center;margin-right:10px;padding-bottom:5px;">'
            .concat(_boxDOM)
            .concat(_labelDOM, "</div>");
      }
    }

    lineLegendDOM =
      '<div style="display:flex;flex-wrap: wrap;flex-basis:50%;justify-content:flex-end;">'.concat(
        lineLegendDOM,
        "</div>"
      );

    let legendHtml =
      '<div style="display:flex;flex-wrap: wrap;box-sizing: border-box;">'
        .concat(barLegendDOM)
        .concat(lineLegendDOM, "</div>");

    return legendHtml;
  }

  return { draw: drawChart };
};

const OOSD3Component = ({
  widgetId,
  widgetDt,
  height,
  width,
  getWidgetSize,
  wid,
  slots,
  xAxisMax,
  wonDollarValue,
  salesPersons,
  stages,
  collorPallet,
}) => {
  const d3Container = useRef(null);
  const [backgroundColor, setBackgroundColor] = useState("");
  const adjustedHeight = height - Margin.TOP - Margin.BOTTOM;
  const adjustedWidth = width - Margin.RIGHT - Margin.LEFT;
  const isTopBarHide = useSelector((state) => state.layout.IsHeaderDisplayed);

  useEffect(() => {
    getWidgetSize();

    if (!_.isEmpty(widgetDt) && height > 0 && width > 0) {
      const svg = d3.select(d3Container.current);

      // try {
      //   console.log("widgetDt", widgetDt);
      //   widgetDt = sampleDataWithOverlapped;
      //   widgetDt = sampleDataWithSingleBubble;
      //   widgetDt = sampleDataWithSingleDiffBubble;
      //   widgetDt = OOSDt15Overlap;
      //   widgetDt = OOSDt15OverlapWon;
      //   widgetDt = OOSDt10;

      //   stages = widgetDt.map((x) => x.stageName);

      //   console.log("widgetDt1", widgetDt);
      //   wonDollarValue = widgetDt
      //     .find((x) => x.stageName.toLowerCase() === "won")
      //     .bubbles.map((x) => x.dollarValue)
      //     .reduce((prev, next) => prev + next);
      //   console.log("wonDollarValue", wonDollarValue);
      // } catch (error) {
      //   wonDollarValue = 0;
      // }
      // console.log("slots", slots);

      const chart = OOSWidget(
        svg,
        widgetId,
        adjustedHeight,
        adjustedWidth,
        slots,
        xAxisMax,
        wonDollarValue,
        salesPersons,
        collorPallet
      );

      //chart.draw(sampleData55, stages);
      //chart.draw(sampleDataWithOverlapped, stages);
      //chart.draw(OOSDt15Overlap, stages);
      //chart.draw(OOSDt15OverlapWon, stages);
      //chart.draw(sampleData40, stages);
      //chart.draw(sampleDataWithSingleBubble, stages);
      //chart.draw(sampleData30, stages);
      chart.draw(widgetDt, stages);
      //chart.draw(OOSDt, stages);
      //chart.draw(OOSDt10, stages);

      const backgroundColor1 = slots.length % 2 === 0 ? "#3F3F3F" : "#595959";
      setBackgroundColor(backgroundColor1);
    }
  }, [
    widgetDt,
    height,
    width,
    wid,
    slots,
    xAxisMax,
    wonDollarValue,
    salesPersons,
    stages,
    collorPallet,
    isTopBarHide,
  ]);

  return (
    <Fragment>
      <div id={widgetId} className="ooswidget">
        <div
          id={`${widgetId}-dom-legend`}
          data-elementid={widgetId}
          style={{
            padding: "5px 0",
            background: backgroundColor,
          }}
        ></div>
        <svg ref={d3Container} height={height} width={width}></svg>
      </div>
    </Fragment>
  );
};

export default OOSD3Component;
